import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import pageStyles from "./styles.scss";

function references() {
    return (  
        <main>
            {/* TOP BANNER */}
            <div
            style={{
                height: "auto",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
            >
            <div>
                <StaticImage
                placeholder="blurred"
                src="../images/imr_video_top.png"
                alt="cityscape background"
                />
            </div>
            <p
                style={{
                fontFamily: "Montserrat",
                fontSize: "2vw",
                position: "absolute",
                color: "white",
                }}
                className='resp-title'
            >
                Providing affordable high quality video production and photography
            </p>
            </div>
            {/* END TOP BANNER */}

            {/* NAV */}
            <div className="imr-nav">
            <div className="imr-inner-nav">
                <a href="/"><div className="imr-nav-link">Video</div></a>
                <a href="/designportfolio"><div className="imr-nav-link">Design</div></a>
                <a href="/references"><div className="imr-nav-link">References</div></a>
            </div>
            </div>
            {/* END NAV */}
            <div className='references-container'>
                <div className='inner-reference'>
                    <h2 className='reference-text'>
                        "We've used Images Made Real for a range of initiatives including concept formulation, creative, design and production in both print, video and digital media. Projects include advertising materials, industrial video, the design and build of the first municipal Economic Development site in Southern Ontario while the internet was still publicly a fledging channel and collateral materials of various nature. We have won a number of international advertising and promotion awards together over the years. I have and continue to refer Zlatko and Images Made Real to colleagues, and companies requiring the services he provides."
                    </h2>
                    <h2 className='reference-name'>
                        Peter Myall — Senior Policy Advisor, City of Toronto
                    </h2>
                </div>
                <div className='inner-reference'>
                    <h2 className='reference-text'>
                    Images Made Real has provided PAMA Art Gallery the highest standard of design excellence. We have worked with Zlatko and his wonderful company for many many years and we’ve been nothing but thrilled with his work. His highly creative visual sensibility, having come from an arts background has never disappointed and has always left us completely satisfied with his innovative approach to all the projects that the PAMA Art Gallery and Museum Archives has developed.
                    </h2>
                    <h2 className='reference-name'>
                        Erin Fernandes — PAMA Art Gallery, Marketing Specialist
                    </h2>
                </div>
                <div className='inner-reference'>
                    <h2 className='reference-text'>
                    "I have hired Images Made Real on numerous occasions for design , photography and video and projects. Zlatko always exceeds expectations by bringing quality, creative ideas to the project. His design and video production credentials are impeccable, and best of all; he consistently delivers on time and on budget. He is always a pleasure to work with and I would highly recommend Images Made Real for any projects that require video or design production."
                    </h2>
                    <h2 className='reference-name'>
                    Chris Rickett — Manager, Strategic Economic Initiatives | Chief Administrative Officer | Economic Strategy — Town of Markham
                    </h2>
                </div>
                <div className='inner-reference'>
                    <h2 className='reference-text'>
                    "Partners in Project Green has been working with Images Made Real to film our high profile sustainability speaker events since 2009, and I have always been impressed by their superior service, and the quality of their video production. Zlatko always makes sure the job gets done no matter what challenges we throw his way. When working with Images Made Real, I know that at the end of production we will have a professional product and excellent results to share with our business community. In this day in age, the visual medium is integral to sharing our story. We use Images Made Real because we need to know that the job will be done right, and the costs will be within our means."
                    </h2>
                    <h2 className='reference-name'>
                    Jennifer Taves — Project Manager, Partners in Project Green

Toronto and Region Conservation
                    </h2>
                </div>
                <div className='inner-reference'>
                    <h2 className='reference-text'>
                    "Zlatko Cetinic provides the creative design needed for every project whether big or small. Zlatko is always professional, friendly and personable, with an eye for exactly what's needed - in a field where it can be so hard to articulate what you want."
                    </h2>
                    <h2 className='reference-name'>
                    Marianne McAfee — Strategic Communications Lead at Ministry of Natural Resources
                    </h2>
                </div>
            </div>
        </main>
    );
}

export default references;